export interface IOrderCounter {
  cart_count: number;
  inbox_count: number;
}

export class CartOrderCounterModel implements IOrderCounter {
  cart_count: number;
  inbox_count: number;

  constructor(cart_count: number, inbox_counter: number) {
    this.cart_count = cart_count;
    this.inbox_count = inbox_counter;
  }

  getCOC() {
    return <IOrderCounter>{ cart_count: this.cart_count, inbox_count: this.inbox_count };
  }

  // setCOC(data: IOrderCounter) {
  //   const { cart_count, need_change_order_count } = data;
  //   this.cart_count = cart_count;
  //   this.need_change_order_count = need_change_order_count;
  // }
}
