// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '1.5.0',
  USERDATA_KEY: 'auth',
  isMockEnabled: true,
  apiUrl: 'https://api-staging.maxxiagri.com/keputran',
  appThemeName: 'Turi',
  appPreviewChangelogUrl: '',
  appPreviewDocsUrl: '',
  firebase: {
    apiKey: 'AIzaSyCywUYD9OOcbihsSWmslbxqQ39JfR6oIC8',
    authDomain: 'distributorapps.firebaseapp.com',
    projectId: 'distributorapps',
    storageBucket: 'distributorapps.appspot.com',
    messagingSenderId: '606578145348',
    appId: '1:606578145348:web:b2254ca08853543b26e411',
    measurementId: 'G-W44HJ9TF5T',
    vapidKey: 'BJ55lxWelsx0UTVdpbOqzOhjHyGf3Iz8b5zR8munAS0my_Ee8mAyPs6lNo7GHVsye5NiAOSPz2TCiIUVlPBaFC4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
