import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { MatSnackComponent } from '@shared/components/matsnack/matsnack.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar) {}

  _default: MatSnackBarConfig = {
    duration: 3000,
    verticalPosition: 'bottom',
    horizontalPosition: 'end',
  };

  show(message: string, action?: string, type?: 'default' | 'success' | 'error' | 'info' | 'warning', config?: MatSnackBarConfig) {
    const data = { message: message, action: action };
    const _panelClass =
      type === 'success'
        ? ['snackbar-success', 'bg-success']
        : type === 'error'
        ? ['snackbar-error', 'bg-danger', 'text-white']
        : type === 'info'
        ? ['snackbar-info', 'bg-info']
        : type === 'warning'
        ? ['snackbar-warning', 'bg-warning']
        : ['snackbar-default', 'bg-gray-900'];

    const _config = {
      data,
      panelClass: _panelClass,
      ...this._default,
      ...config,
    };

    return this.matSnackBar.openFromComponent(MatSnackComponent, _config);
  }

  galat() {
    this.show('<span class="text-white">Sedang ada Gangguan! Coba beberapa saat lagi</span>', '<span class="text-white">OK</span>', 'error');
  }

  errorLogin(errMsg: string) {
    this.show(`<span class="text-white">${errMsg}</span>`, '<span class="text-white">Ok</span>', 'error');
  }

  errorCart_noTotalPrice() {
    this.show('<span class="text-white">Gagal memproses keranjang! Ada kesalahan total harga!</span>', '<span class="text-white">OK</span>', 'error', {
      duration: 5000,
    });
  }

  errorCart_noCheckedItems() {
    this.show('<span class="text-white">Select item(s) to proceed order</span>', '<span class="text-white">OK</span>', 'error');
  }

  errorCheckout_noPaymentMethod() {
    this.show('<span class="text-white">Pilih Jenis Pembayaran terlebih dahulu</span>', '<span class="text-white">OK</span>', 'error');

    return false;
  }

  errorCheckout_noDeliveryAddress() {
    this.show('<span class="text-white">Pilih Alamat terlebih dahulu</span>', '<span class="text-white">OK</span>', 'error');

    return false;
  }

  removeCartSnackbar(name: string = '', showAction = true) {
    this.matSnackBar.dismiss();
    let _desc = 'Barang telah dihapus dari keranjang';
    if (name) {
      _desc = name + ' berhasil dihapus.';
    }

    let _action = showAction ? `<span class="text-white fw-bolder">BATAL</span>` : undefined;

    return this.show(`<p class="text-capitalize text-white mb-0">${_desc}</p>`, _action, 'default', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }
}
