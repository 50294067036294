import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { UserModel } from '@models/user.model';
import { AuthModel } from '@models/auth.model';
import { environment } from '@environments/environment';
import { API } from '@config/constants/api.constant';

export type User = UserModel;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `turi_${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<User>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<User>;
  isLoadingSubject: BehaviorSubject<boolean>;

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(private router: Router, private http: HttpClient) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<User>(this.getAuthFromLocalStorage() as User);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      this.currentUserSubject.next(auth as User);
      return true;
    }

    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      return JSON.parse(lsValue);
    } catch (error) {
      // console.error('error ', error);
      return undefined;
    }
  }

  login(user_id: string, password: string): Observable<User> {
    this.isLoadingSubject.next(true);
    return this.http.post<UserModel>(API.BASE_AUTH_DISTRIBUTOR, { user_id, password }).pipe(
      map((auth: AuthModel) => {
        return this.setAuthFromLocalStorage(auth);
      }),
      catchError((err) => {
        return of(err);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.currentUserSubject.next(new UserModel());
    return this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  isLoggedIn(): boolean {
    if (!this.currentUserValue) return false;
    return this.currentUserValue && !!Object.keys(this.currentUserValue).length;
  }

  isCBDUser() {
    return this.currentUserValue.isCbdUser;
  }

  getUserToken(): string {
    return this.currentUserValue.token;
  }

  getUserName() {
    return this.currentUserValue.email.split('@')[0];
  }

  hasTokenFcm() {
    return !!this.currentUserValue.tokenFcm;
  }

  addTokenFcmToLS() {
    return this.setAuthFromLocalStorage(this.currentUserValue);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
