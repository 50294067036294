<div #customMatSnack
     class="custom-mat-snack d-flex flex-wrap align-items center justify-content-between">
  <div [innerHTML]="data.message" class="flex-grow-1"></div>

  <button
    *ngIf="data.action"
    class="btn btn-transparent p-0 ms-4"
    (click)="snackbarRef.dismissWithAction()"
    [innerHTML]="data.action"
  ></button>
</div>
