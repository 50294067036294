import { Injectable } from '@angular/core';
import { BaseService } from '@services/base.service';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from '@services/snackbar.service';
import { AuthService } from '@services/auth.service';
import { API } from '@config/constants/api.constant';
import { CartOrderCounterModel, IOrderCounter } from '@shared/interface/order-counter.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartOrderCounterService extends BaseService {
  COCModel = new CartOrderCounterModel(0, 0);
  cartOrderCounter$: Observable<IOrderCounter>;
  private cartOrderCounterSubject = new BehaviorSubject(this.COCModel.getCOC());

  constructor(httpClient: HttpClient, snackbarService: SnackbarService, authService: AuthService) {
    super(httpClient, authService, snackbarService);
    this.cartOrderCounter$ = this.cartOrderCounterSubject.asObservable();
  }

  get CartOrderCounter() {
    return this.cartOrderCounterSubject.value;
  }

  getOrderCounter() {
    return this.getResponse<IOrderCounter>(API.INITIAL_DISTRIBUTOR).pipe(
      shareReplay(),
      map((resp) => {
        this.setOrderCounter(resp.data);
        return resp.data;
      })
    );
  }

  setOrderCounter(data: IOrderCounter) {
    this.cartOrderCounterSubject.next(data);
  }

  // getCOCStorageName() {
  //   const uname = this.authService.getUserName();
  //   return this.cocStorage + '-' + uname;
  // }

  // setCOCLocalStorage(data: IPurchaseOrderCounter) {
  //   if (!data) return;
  //   localStorage.setItem(this.getCOCStorageName(), JSON.stringify(data));
  // }
  //
  // getCOCLocalStorage() {
  //   try {
  //     const lsValue = localStorage.getItem(this.getCOCStorageName());
  //     if (!lsValue) return undefined;
  //     return JSON.parse(lsValue);
  //   } catch (err) {
  //     return undefined;
  //   }
  // }
}
