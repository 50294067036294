import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@shared/base/base-response.model';
import { API } from '@config/constants/api.constant';
import { Observable, shareReplay, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarService } from '@services/snackbar.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(private httpClient: HttpClient, public authService: AuthService, public snackbarService?: SnackbarService) {}

  public getResponse<T>(url: string): Observable<BaseResponse<T>> {
    return this.httpClient.get<BaseResponse<T>>(API.BASE + url).pipe(
      shareReplay(),
      catchError((err: HttpErrorResponse) => {
        this.showErrorSnack(err);
        return throwError(() => err);
      }),
      map((response) => {
        return response;
      })
    );
  }

  public getPatchResponse<T>(url: string, body: any) {
    return this.httpClient.patch<BaseResponse<T>>(API.BASE + url, body).pipe(
      shareReplay(),
      catchError((err: HttpErrorResponse) => {
        this.showErrorSnack(err);
        return throwError(() => err);
      }),
      map((response) => response)
    );
  }

  public getResponseData<T>(url: string): Observable<T> {
    return this.httpClient.get<BaseResponse<T>>(API.BASE + url).pipe(
      shareReplay(),
      map((response) => response.data)
    );
  }

  // post
  public postData<T>(url: string, body: T): Observable<BaseResponse<T>> {
    return this.httpClient.post<BaseResponse<T>>(API.BASE + url, body).pipe(
      shareReplay(),
      catchError((err: HttpErrorResponse) => {
        this.showErrorSnack(err);
        return throwError(() => err);
      }),
      map((response) => response)
    );
  }

  // put
  public putData<T>(url: string, body: T): Observable<BaseResponse<T>> {
    return this.httpClient.put<BaseResponse<T>>(API.BASE + url, body).pipe(
      shareReplay(),
      catchError((err: HttpErrorResponse) => {
        this.showErrorSnack(err);
        return throwError(() => err);
      }),
      map((response) => response)
    );
  }

  // delete
  public deleteData<T>(url: string, body?: T): Observable<BaseResponse<T>> {
    return this.httpClient.delete<BaseResponse<T>>(API.BASE + url, { body }).pipe(map((response) => response));
  }

  removeUndefinedParam(param: any, handlePaging = true) {
    if (handlePaging && param.page) {
      param.page = param.page - 1;
    }

    for (let key in param) {
      (param[key] === undefined || param[key] === '' || param[key] === null) && delete param[key];
    }

    return this.handleQueryObject(param);
  }

  handleQueryObject(url: any) {
    let query = [];
    for (let key in url) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(url[key]));
    }

    return query.length ? '?' + query.join('&') : '';
  }

  showErrorSnack(resp: HttpErrorResponse, errMsg: string = 'Something went wrong!') {
    let status = resp.status;
    let msg = errMsg;

    if (resp.error) {
      msg = resp.error.message;
    }

    // unknown error
    if (!resp.error.message) {
      msg = resp.statusText + '. Status: ' + status;
    }

    if (resp.error.errors) {
      const { code, message } = resp.error.errors[0];
      status = code;
      msg = message;
    }

    const snackbarShow = this.snackbarService?.show('<span class="text-white">' + msg + '<span>', '<span class="text-white text-uppercase">Ok</span>', 'error');

    // based on atom: status 401 is for expired session need to log out
    snackbarShow?.afterDismissed().subscribe(() => {
      if (status === 401) return setTimeout(() => this.authService.logout(), 1500);
    });

    return true;
  }
}
