import { IDeviceInfo } from '@services/device.service';

export class AuthModel {
  email: string;
  avatar: any;
  role: string;
  token: string;
  tokenFcm: string;
  isCbdUser: boolean;
  deviceInfo?: IDeviceInfo;
}
