import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-matsnack',
  templateUrl: './matsnack.component.html',
  styleUrls: ['./matsnack.component.scss'],
})
export class MatSnackComponent implements OnInit {
  constructor(
    public snackbarRef: MatSnackBarRef<MatSnackComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
