import { environment } from '@environments/environment';

export class API {
  public static get BASE() {
    return `${environment.apiUrl}/`;
  }

  // AUTH
  // public static get BASE_AUTH_BACKOFFICE() { return API.BASE + 'auth/backoffice' }
  public static get BASE_AUTH_DISTRIBUTOR() {
    return API.BASE + 'auth/distributor';
  }

  public static get INITIAL_DISTRIBUTOR() {
    return 'v1/initial/distributor';
  }

  public static get PRODUCT_CATEGORY() {
    return 'v1/product/distributor/filter-category';
  }

  public static get YOUR_PRODUCT_LIST_CATEGORY() {
    return 'v1/variant/distributor/list-your-product';
  }

  public static get OTHER_PRODUCT_LIST_CATEGORY() {
    return 'v1/variant/distributor/list-other-product';
  }

  public static get PRODUCT_DETAIL() {
    return 'v1/product/distributor/detail-product';
  }

  public static get CART_DETAIL() {
    return 'v2/cart/distributor/get-cart';
  }

  public static get CART_DETAIL_SELECTED() {
    return 'v2/cart/distributor/get-selected-item';
  }

  public static get CART_UPDATE() {
    return 'v2/cart/distributor/update-cart';
  }

  public static get CART_ADD() {
    return 'v2/cart/distributor/add-cart';
  }

  public static get CART_DELETE() {
    return 'v2/cart/distributor/delete-cart';
  }

  public static get CHECKOUT_DIRECT_ORDER_CART() {
    return 'v1/purchase-orderV2/distributor/create-po';
  }

  public static get CHECKOUT_DIRECT_ORDER() {
    return 'v1/purchase-orderV2/distributor/direct-order';
  }

  public static get CHECKOUT_DIRECT_ORDER_DETAIL() {
    return 'v2/cart/distributor/direct-order';
  }

  public static get PURCHASE_ORDER_LIST() {
    return 'v1/purchase-orderV2/distributor/purchase-order/list';
  }

  public static get PURCHASE_ORDER_COUNT() {
    return 'v1/purchase-order/distributor/list-status';
  }

  public static get PURCHASE_ORDER_DETAIL() {
    return 'v1/purchase-order/distributor/purchase-order/detail';
  }

  public static get PURCHASE_ORDER_DETAIL_EDIT() {
    return 'v1/purchase-order/distributor/edit-po';
  }

  public static get PURCHASE_ORDER_EDIT_SELECTED_CART() {
    return 'v2/cart/distributor/counting-price/edit';
  }

  public static get PURCHASE_ORDER_UPDATE() {
    return 'v1/purchase-orderV2/distributor/edit-po';
  }

  public static get PURCHASE_ORDER_CANCEL() {
    return 'v1/purchase-orderV2/distributor/cencel-purchase-order';
  }

  public static get PURCHASE_ORDER_EDIT_LOCK() {
    return 'v1/purchase-order/distributor/purchase-order/lock';
  }

  public static get PURCHASE_ORDER_EDIT_UNLOCK() {
    return 'v1/purchase-order/distributor/purchase-order/unlock';
  }

  public static get PURCHASE_ORDER_LOCK_STATUS() {
    return 'v1/purchase-order/distributor/purchase-order/lock/status';
  }

  public static get PURCHASE_ORDER_PAYMENT_DETAIL() {
    return 'v1/initial/distributor/payment-detail';
  }

  public static get PURCHASE_ORDER_REWARDS() {
    return 'v1/purchase-order/distributor/purchase-order/detail/program-marketing' //:po_detail
  }

  public static get CART_ORDER_COUNTER() {
    return 'v1/cart/distributor/count-data-order';
  }

  public static get DISTRIBUTOR_ADDRESS_LIST() {
    return 'v1/distributor/address-list';
  }

  public static get DISTRIBUTOR_BUSINESS_DATA() {
    return 'v1/distributor/detail-profile/data-bussiness';
  }

  public static get DISTRIBUTOR_CREDIT_CEILING() {
    return 'v1/distributor/detail-profile/credit-ceiling';
  }

  public static get DISTRIBUTOR_ADDRESS_DELIVERY() {
    return 'v1/distributor/detail-profile/address-delevery';
  }

  public static get DISTRIBUTOR_PROFILE_DOCUMENT() {
    return 'v1/distributor/detail-profile/document';
  }

  public static get DISTRIBUTOR_DETAIL_PROFILE() {
    return 'v1/distributor/detail-profile/data-distributor';
  }

  public static get DISTRIBUTOR_DETAIL_PROFILE_HEADER() {
    return 'v1/distributor/detail-profile/header';
  }

  public static get DISTRIBUTOR_CHANGE_PASSWORD() {
    return 'v1/distributor/change-password';
  }

  public static get ACCESS_LOG() {
    return 'v1/access-log/distributor';
  }

  public static get SEARCH_RESULT() {
    return 'v1/product/distributor/list-product/search-result';
  }

  public static get SEARCH_HISTORY() {
    return 'v1/last-seen/search-history';
  }

  public static get ADD_OTHER_PRODUCTS_LIST() {
    return 'v1/variant/distributor/edit-po/list-other-variants';
  }

  public static get DISTRIBUTOR_CBD_DISCOUNT() {
    return 'v1/discount-setting/distributor/get-discount-cbd';
  }

  public static get POST_FCM_TOKEN() {
    return 'v1/initial/distributor/set-token-firebase';
  }

  // NOTIFICATION
  public static get NOTIFICATION_LIST() {
    return 'v1/inbox/distributor/list-notification';
  }

  public static get NOTIFICATION_READ_ALL() {
    return 'v1/inbox/distributor/read-all';
  }

  public static get NOTIFICATION_READ() {
    return 'v1/inbox/distributor/read';
  }
}
