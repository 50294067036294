import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from '@environments/environment';
import { BaseService } from '@services/base.service';
import { API } from '@config/constants/api.constant';
import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  messageSubject = new BehaviorSubject<any>(null);

  constructor(private baseService: BaseService, private authService: AuthService, private notifService: NotificationService) {}

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          // handle token data
          if (!this.authService.hasTokenFcm()) {
            let _user = this.authService.currentUserSubject.value;
            this.authService.currentUserValue = {
              ..._user,
              tokenFcm: currentToken,
            };

            this.authService.addTokenFcmToLS();
            this.postFcmToken(currentToken);
          }
        }
      })
      .catch((err) => err);
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.notifService.getNotification();
      this.messageSubject.next(payload);
    });
  }

  postFcmToken(token: string) {
    const postToken$ = this.baseService.postData(API.POST_FCM_TOKEN + '/' + token, undefined);
    return postToken$.subscribe((resp) => resp);
  }
}
