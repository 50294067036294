import {BaseService} from "@services/base.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SnackbarService} from "@services/snackbar.service";
import {AuthService} from "@services/auth.service";
import {BehaviorSubject} from "rxjs";
import {INotification} from "@shared/interface/notification.interface";
import {API} from "@config/constants/api.constant";
import {Router} from "@angular/router";
import {IParamQuery} from "@services/purchase-order.service";
import {CartOrderCounterService} from "@services/cart-order-counter.service";

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  paramQuery = {
    string_filter: undefined,
    start_date: undefined,
    end_date: undefined,
    size: 10,
    status: undefined,
    page: 1,
  };

  indexBeforeLabelNotification: number | null = null;
  notificationData: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([] as INotification[]);
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  lastPage = false;
  currentPage = 0;
  noMoreItems = false;

  // private notificationCounterSubject = new BehaviorSubject('');

  constructor(
    httpClient: HttpClient,
    snackbarService: SnackbarService,
    authService: AuthService,
    public router: Router,
    private cocService: CartOrderCounterService,
  ) {
    super(httpClient, authService, snackbarService);
  }

  get NotificationData() {
    return this.notificationData;
  }

  getNotification(query: IParamQuery = this.paramQuery) {
    if (!this.isLoading.value) return;
    const params = this.removeUndefinedParam(query);
    return this.getResponse<INotification[]>(API.NOTIFICATION_LIST + params).subscribe(val => {
      if (!val) return;
      this.lastPage = val.is_last;
      this.notificationData.next(this.notificationData.value.concat(val.data))
      this.currentPage = val.current_page + 1;
      this.isLoading.next(false);
    });
  }

  changeLabelNotification(item: any, index: number) {
    if (this.indexBeforeLabelNotification) {
      return false;
    }

    if (!item.is_this_week) {
      this.indexBeforeLabelNotification = index;
    }

    return true;
  }

  enableReadAllNotification() {
    const _check = this.NotificationData.value.find(val => !val.is_read)
    return !_check
  }

  readNotification(id: string) {
    this.isLoading.next(true);
    this.emptyDataNotification();
    this.getPatchResponse<any>(`${API.NOTIFICATION_READ}/${id}`, {}).subscribe(val => {
      this.cocService.getOrderCounter();
      return val;
    });
    this.getNotification();
  }

  readAllNotification() {
    this.isLoading.next(true);
    this.emptyDataNotification();
    this.getPatchResponse<any>(`${API.NOTIFICATION_READ_ALL}`, {}).subscribe(val => {
      this.cocService.getOrderCounter();
      return val;
    });
    this.getNotification();
  }

  goToDetail(data: INotification) {
    this.readNotification(data.inbox_id);
    setTimeout(() => {
      this.router.navigate(['/purchase-order/detail/' + data.purchase_order_id], {
        state: {
          notificationId: data.inbox_id,
          isNotificationRead: data.is_read,
          purchaseOrderId: data.purchase_order_id,
          salesOrderId: data.sales_order_id,
        }
      }).then(() => window.location.reload());
    }, 500)
  }

  emptyDataNotification() {
    this.notificationData.next([]);
  }
}
